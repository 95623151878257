@import "colors";

.registration_intro_text > .label_input {
  padding-left: 0!important;
  text-align: justify!important;
}

.eunomia-wrapper {
  background-color: $eunomia-wrapper;
  color: $lighter-text-color;
}
.eunomia-cascade-link {
  color: $lighter-text-color;
}

.eunomia-info-view {
  background-color: $eunomia-action-bar-wrapper;
  color: $lighter-text-color;
  flex-direction: column;
  padding: 10px;
}

.eunomia-action-bar-wrapper {
  background-color: $eunomia-action-bar-wrapper;
  color: $lighter-text-color;
  padding-left: 4px;
  padding-right: 4px;
}

.eunomia-cascade-action-bar-wrapper {
  background-color: $eunomia-cascade-action-bar-wrapper;
  color: $lighter-text-color;
  padding-left: 5px;
}

.eunomia-cascade-info-view {
  background-color: $eunomia-cascade-action-bar-wrapper;
  color: $lighter-text-color;
  flex-direction: column;
  padding: 10px;
}

.focusable {
  &:focus {
    .detailed-status__action-bar {
      background: $ui-base-color!important;
    }
  }
}

.eunomia-menu-icon-wrapper {
  padding: 2px;
  margin-top: 2px;
  display: flex;
  margin-right: 1px;
}

.eunomia-menu-icon-counter {
  font-size: small;
  margin-top: 4px;
  margin-left: -2px;
  z-index: 4;
}

.eunomia-menu-icon {
  width: 90%;
  height: 90%;
  object-fit: contain;
  margin-left: 0;
  z-index: 4;
}

.status__wrapper:focus {
  color: inherit!important;
  background-color: inherit!important;
  transition: none!important;
  transition-property: none!important;
}

.eunomia-indicators-header-view {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.eunomia-indicators-close {
  color: $lighter-text-color;
  background: transparent;
  width: 14px;
  height: 14px;
  border: 1px solid $lighter-text-color;
  border-radius: 8px;
  position: relative;
}
.eunomia-indicators-close:after {
  content: '';
  height: 14px;
  border-left: 1px solid $lighter-text-color;
  position: absolute;
  transform: rotate(45deg);
  left: 7px;
}

.eunomia-indicators-close:before {
  content: '';
  height: 14px;
  border-left: 1px solid $lighter-text-color;
  position: absolute;
  transform: rotate(-45deg);
  left: 7px;
}

.eunomia-indicators-close:hover {
  cursor: pointer;
}

.eunomia-icon-button {
  color: $lighter-text-color;
  margin-right: 10px!important;
}

.eunomia-discuss-button:hover {
  background-color: initial!important;
}

.eunomia-icon-button:hover, .eunomia-icon-button:active, .eunomia-icon-button:focus, .eunomia-icon-button.disabled {
  color: $lighter-text-color!important;
  background-color: inherit!important;
  transition: none!important;
  transition-property: none!important;
}
.status__eunomia__cascade__content a {
  color: inherit!important;
}

.status__content__text__eunomia:hover {
  cursor: default!important;
}

.floating-action-button {
  z-index: 9999;
}

.cascade-load-more-button {
  border: thin $lighter-text-color solid;
}
.logo-container h1 a svg {
    display: none;
}
.logo-container h1 a {
    content: $logo-name;
    max-height: 160px;
}
.drawer__inner__mastodon > img {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 0 10px 10px 10px;
}

.voting-button-text-link {
  //margin-left: -11px;
  margin-right: 2px;
  margin-left: -6px;
  text-decoration: none;
  font-size: 16px;
  color: $eunomia-indicators-text;
}

.guideline-message {
  color: $ui-secondary-color;
  font-style: italic;
  font-size: larger;
  text-align: justify;
  margin-top: 12px;
}

.eunomia-stats {
  margin-top: 20px;
  & div {
    font-size: large;
  }
}

.eunomia-nudge {
  margin-top: 5px;
  text-align: center;
  font-size: small;
  border: thin $light-text-color solid;
  color: $light-text-color;
}

.eunomia-information-cascade-view {
  margin: 10px 0;
  height: calc(100vh - 20px);
  overflow-y: auto;
}


.react-tiny-popover-container {
  z-index: 9!important;
  background-color: transparent!important;
}
.eunomia-blockchain-popover {
  background-color: rgba(0,0,0,0.8);
  color: rgba(255,255,255,0.8);
  max-width: 480px;
  padding: 5px;
}

.eunomia-cascade-post-view {
  color: $cascade-post-text;
}

.eunomia-cascade-selected-post-view {
  color: #544895;
}

.eunomia-info-cascade-icon {
  content: $info_cascade_name;
}
