$eunomia-dark: #4f3d88;
$eunomia-dark-alt: #564b93;
$eunomia-light: #2196f3;
$eunomia-light-alt:#4ec5e8;
//$eunomia-label: #beb0c9;
$eunomia-label: #ccc;
$eunomia-primary: $eunomia-dark;
$eunomia-secondary: $eunomia-light;
$eunomia-neutral: #fff;
$eunomia-pure-neutral: #fff;
$eunomia-positive: #3d85c6;
$eunomia-positive-alt: #3d85c6;
$eunomia-negative: #f6b26b;
$eunomia-negative-alt: #f6b26b;
$eunomia-action-bar-wrapper: #191b22;
$eunomia-wrapper: #313543;
//$eunomia-action-bar-wrapper: #313543;
$eunomia-indicators-text: #9baec8;
$eunomia-cascade-action-bar-wrapper: #282c37;
$motd: $lighter-text-color;
$nudge-border:  #ffffff4a;
$logo-name: url(../images/logo_dark.png);
$info_cascade_name: url(../images/info_cascade_dark.png);
$cascade-post-text: #d9e1e8;
